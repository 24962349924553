import React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import HeroImage from '../components/HeroImage'
import ContactForm from '../components/ContactForm'
import Map from '../components/Map'
import Hours from '../components/Hours'
import SEO from '../components/SEO'



const Contact = ({ data }) => {
  return(
    <Layout>
    <SEO
      title="Contact"
    />
    <div id="page-title" className='padding-content-section alert-dismissed'>
      <h3 className="responsive-title">Contact Us</h3>
      <div>
        <p>
          For any questions, or to book an appointment, contact mBody Health via phone, email,
          or using the contact form below.
        </p>
        <p><strong>Phone:</strong> {data.site.siteMetadata.contact.phone}</p>
        <p><strong>Email:</strong> {data.site.siteMetadata.contact.email}</p>
      </div>
      <ContactForm />
      </div>
      <HeroImage heroText="Hours" subtext={<Hours />} cta="" class="hero-image-alt" source='/static/lymphatic-771fe38ee8af92956bf817de79d0754c.jpg'/>
      <div id="location">
        <Map />
      </div>
    </Layout>
  )
}

export default Contact;

export const query = graphql`
query{
  site{
    siteMetadata{
      contact{
        phone
        email
      }
    }
  }
}
`
