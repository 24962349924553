import React from 'react'


const ContactForm = () => (
  <div className="contact-form">
    <form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/form-submitted"
    >
      <input type="hidden" name="form-name" value="contact" />
      <p class="hidden">
        <label>Don’t fill this out if you're human:
        <input name="bot-field" /></label>
      </p>
      <p className="form-row">
        <label for="name">Name</label>
        <input type="text" name="name" placeholder="Full Name" required/>
      </p>
      <p className="form-row">
        <label for="budget">I'm looking to:</label>
        <select name="budget" required>
          <option value="Request an Appointment"> Request an Appointment</option>
          <option value="Ask a Question About Insurance">Ask a Question About Insurance</option>
          <option value="Ask a General Question">Ask a General Question</option>
          <option value="Request Info for Corporate Massages">Request Info for Corporate Massages</option>

        </select>
      </p>
      <p className="form-row">
         <label for="email">Email Address</label>
         <input type="email" name="email" placeholder="youremail@email.com" required/>
      </p>
      <p className="form-row">
         <label for="message">Message</label>
         <textarea name="message" placeholder="Add your availability or question here." required></textarea>
      </p>
      <p className="form-row">
         <button type="submit" className="form-button button button-primary">Send</button>
      </p>
    </form>
  </div>
)

export default ContactForm;
