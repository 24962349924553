import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

const HeroImage = props => {
  const data = useStaticQuery(query);
  function getNodeFromSource(){
    let list = data.allImageSharp.nodes
    for (var i = 0; i < list.length; i++){
        if (list[i].original.src === props.source){
          return i;
        }
      }
    }

        return(
          <div>
            <Img
              fluid={data.allImageSharp.nodes[getNodeFromSource()].fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              className={props.class}
            />
            <div className="text-container">
              {
                props.cta === "" ?
                (
                  <div>
                    <h3 className="hero-text">{props.heroText}</h3>
                    <div className={props.class === "hero-image-alt" ? "hours-text" : "sub-text"}>{props.subtext}</div>
                  </div>
                )
                :
                (
                  <div>
                    <h3 className="hero-text">{props.heroText}</h3>
                    <div className="sub-text">{props.subtext}</div>
                    <Link to="/contact"><button className="button-hero button-primary">{props.cta}</button></Link>
                  </div>
                )
              }
            </div>
          </div>
        )
}
export default HeroImage;

const query = graphql`
query{
  allImageSharp{
    nodes {
      original {
        width
        height
        src
      }
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
