import React from 'react'

const hours_weekday = "9:00 AM – 5:00 PM"
const hours_fri = "9:00 AM – 12:00 PM"
const hours_sat = "9:00 AM – 3:00 PM"
const hours_by_appt = 'BY APPOINTMENT ONLY'
const hours_closed = 'Closed'

const Hours = () => (
  <div>
    <div>
      <div className="hours">Monday – <span style={{marginLeft: '0.5rem'}}>{hours_closed}</span></div>
      <div className="hours">Tuesday – <span style={{marginLeft: '0.5rem'}}>{hours_weekday}</span></div>
      <div className="hours">Wednesday – <span style={{marginLeft: '0.5rem'}}>{hours_weekday}</span></div>
      <div className="hours">Thursday – <span style={{marginLeft: '0.5rem'}}>{hours_weekday}</span></div>
      <div className="hours">Friday – <span style={{marginLeft: '0.5rem'}}>{hours_closed}</span></div>
      <div className="hours">Saturday – <span style={{marginLeft: '0.5rem'}}>{hours_sat}</span></div>
    </div>
  </div>
)

export default Hours;
